<template>
  <v-fade-transition>
    <div v-if="status" v-bind="$attrs" class="d-flex align-center">
      <v-progress-circular size="15" width="2" color="primary" indeterminate />
      <span class="pl-2 text-caption text-truncate">
        {{ status }}
      </span>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  name: "TaskStatus",
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: null,
    },
  },
};
</script>
