<template>
  <VxForm v-slot="{ submitting }" v-bind="$attrs" v-on="listeners">
    <VxFormGroup>
      <VxTextField
        v-model="form.name"
        :label="$t('daybook.setup.form.formLabel')"
        autocomplete="off"
        :placeholder="$t('daybook.setup.form.formPlaceholder')"
        name="name"
        rules="required"
      />
      <div
        class="d-flex d-sm-inline-flex justify-space-between justify-sm-start mb-4 mb-sm-0"
      >
        <TaskRepeatMenu :task.sync="form" :loading="loading" />
        <TaskDueTimeMenu :task.sync="form" :loading="loading" />
        <TaskAccessMenu :task.sync="form" :loading="loading" />
      </div>
      <v-fade-transition>
        <VxBtn v-if="task.id" outlined type="button" @click="onTaskNew">
          <v-icon left>$plus</v-icon>
          {{ $t("daybook.setup.form.newTaskBtn") }}
        </VxBtn>
      </v-fade-transition>
    </VxFormGroup>

    <v-slide-y-transition>
      <VxFormGroup v-if="hasTasks" :title="$t('daybook.setup.form.listTitle')">
        <TaskList
          class="mb-4"
          :tasks="form.tasks"
          draggable
          editable
          @edit="onTaskEdit"
          @delete="$emit('delete:task', $event)"
          @reposition="$emit('reposition:task', $event)"
        />
      </VxFormGroup>
    </v-slide-y-transition>

    <TaskDialog
      v-model="taskDialog"
      :task="selectedTask"
      @ok="onTaskOk"
      @cancel="onTaskCancel"
    />

    <VxFormActions>
      <TaskStatus :status="status" />
      <v-spacer />
      <VxBtn type="submit" :loading="submitting">
        <v-icon left>$save</v-icon>
        {{ $t("daybook.setup.form.saveForm") }}
      </VxBtn>
    </VxFormActions>
  </VxForm>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import TaskRepeatMenu from "./TaskRepeatMenu.vue";
import TaskDueTimeMenu from "./TaskDueTimeMenu.vue";
import TaskAccessMenu from "./TaskAccessMenu.vue";
import TaskDialog from "./TaskDialog.vue";
import TaskStatus from "./TaskStatus.vue";
import {
  VxForm,
  VxFormGroup,
  VxFormActions,
  VxTextField,
  VxBtn,
} from "@/core-ui";

export default {
  name: "TaskForm",
  components: {
    VxForm,
    VxFormGroup,
    VxFormActions,
    VxTextField,
    VxBtn,
    TaskRepeatMenu,
    TaskDueTimeMenu,
    TaskAccessMenu,
    TaskDialog,
    TaskStatus,
    TaskList: () => import("./TaskList.vue"),
  },
  mixins: [
    useFormObject({
      prop: "task",
      default: {
        id: null,
        name: null,
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        dueTime: null,
        tasks: null,
        isManagerOnly: false,
      },
    }),
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    taskDialog: false,
    selectedTask: null,
  }),
  computed: {
    hasTasks() {
      return Array.isArray(this.form.tasks) && this.form.tasks.length > 0;
    },
    listeners() {
      return {
        ...this.$listeners,
        submit: (context) => {
          const values = { ...context.values, ...this.form, tasks: undefined };
          this.$emit("submit", { ...context, values });
        },
      };
    },
  },
  methods: {
    onTaskOk(context) {
      if (!this.form.tasks) {
        this.form.tasks = [];
      }

      if (context.isEdit) {
        this.$emit("edit:task", context);
        return;
      }

      this.$emit("new:task", context);
    },
    onTaskCancel() {
      this.selectedTask = null;
    },
    onTaskNew() {
      this.selectedTask = null;
      this.taskDialog = true;
    },
    onTaskEdit(task) {
      this.selectedTask = { ...task };
      this.taskDialog = true;
    },
  },
};
</script>
