<template>
  <v-dialog
    ref="dialog"
    v-model="modelValue"
    :max-width="550"
    @click:outside="cancel"
    @keydown.esc="cancel"
  >
    <VxForm
      ref="form"
      v-slot="{ submitting }"
      :disabled="loading"
      :observer="{ disabled: true }"
      @submit="ok"
    >
      <v-card>
        <v-card-title
          >{{
            isEdit
              ? $t("daybook.setup.dialog.editTask")
              : $t("daybook.setup.dialog.addTask")
          }}
        </v-card-title>
        <v-card-text class="pt-2 pb-0">
          <VxTextField
            v-model="form.name"
            :label="$t('daybook.setup.dialog.taskLabel')"
            autocomplete="off"
            :placeholder="$t('daybook.setup.dialog.taskPlaceholder')"
            name="name"
            rules="required"
            mode="passive"
            :disabled="isEdit"
            :autofocus="!isEdit"
          />
        </v-card-text>
        <v-card-actions>
          <TaskRepeatMenu :task.sync="form" :loading="loading" />
          <TaskDueTimeMenu :task.sync="form" :loading="loading" />
          <v-spacer />
          <VxBtn :loading="submitting" text type="submit">
            {{ $t("daybook.setup.dialog.taskSave") }}
          </VxBtn>
        </v-card-actions>
      </v-card>
    </VxForm>
  </v-dialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import { VxForm, VxTextField, VxBtn, useModelValue } from "@/core-ui";
import TaskRepeatMenu from "./TaskRepeatMenu.vue";
import TaskDueTimeMenu from "./TaskDueTimeMenu.vue";

export default {
  name: "TaskDialog",
  components: {
    VxForm,
    VxTextField,
    VxBtn,
    TaskRepeatMenu,
    TaskDueTimeMenu,
  },
  mixins: [
    useModelValue(),
    useFormObject({
      prop: "task",
      default: {
        id: null,
        name: null,
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        dueTime: null,
      },
    }),
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEdit() {
      return this.form?.id !== null;
    },
  },
  methods: {
    cancel() {
      this.hide();
      this.$refs.form.submitting = false;
    },
    ok(context) {
      this.$emit("ok", {
        ...context,
        isEdit: this.isEdit,
        values: { ...context.values, ...this.form },
        resolve: () => {
          this.hide();
          context.resolve();
        },
      });
    },
    hide() {
      this.modelValue = false;
      this.form = this.$options.props.task.default();
      requestAnimationFrame(() => this.$refs.form.reset());
    },
  },
};
</script>
