import Vue from "vue";

export function useTaskRepeat() {
  return Vue.extend({
    name: "UseTaskRepeat",
    props: {
      task: {
        type: Object,
        default: () => ({
          sunday: null,
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
        }),
      },
    },
    data: () => ({
      selectedRepeats: [],
    }),
    computed: {
      repeatsFormatted() {
        const repeatDaysCount = this.selectedRepeats.length;

        if (repeatDaysCount === 0) {
          return "";
        } else if (repeatDaysCount === this.repeats.length) {
          return "Daily";
        }

        return repeatDaysCount === 1
          ? this.repeatsMap[this.selectedRepeats[0]].name
          : this.selectedRepeats
              .map((prop) => this.repeatsMap[prop]?.shortName)
              .join("/");
      },
      repeatsMap() {
        return Object.fromEntries(this.repeats.map((x) => [x.propName, x]));
      },
      repeats() {
        return [
          {
            name: "Sunday",
            shortName: "Sun",
            value: this.task.sunday,
            propName: "sunday",
          },
          {
            name: "Monday",
            shortName: "Mon",
            value: this.task.monday,
            propName: "monday",
          },
          {
            name: "Tuesday",
            shortName: "Tue",
            value: this.task.tuesday,
            propName: "tuesday",
          },
          {
            name: "Wednesday",
            shortName: "Wed",
            value: this.task.wednesday,
            propName: "wednesday",
          },
          {
            name: "Thursday",
            shortName: "Thu",
            value: this.task.thursday,
            propName: "thursday",
          },
          {
            name: "Friday",
            shortName: "Fri",
            value: this.task.friday,
            propName: "friday",
          },
          {
            name: "Saturday",
            shortName: "Sat",
            value: this.task.saturday,
            propName: "saturday",
          },
        ];
      },
    },
    watch: {
      repeats: {
        deep: true,
        immediate: true,
        handler(values) {
          this.selectedRepeats = values
            .filter((x) => x.value)
            .map((x) => x.propName);
        },
      },
    },
    methods: {
      updateTask(values) {
        const selectedValues = values.reduce(
          (acc, propName) => ({ ...acc, [propName]: true }),
          {}
        );

        const unselectedValues = this.repeats
          .filter((x) => selectedValues[x.propName] === undefined)
          .reduce((acc, curr) => ({ ...acc, [curr.propName]: false }), {});

        this.$emit("update:task", {
          ...this.task,
          ...selectedValues,
          ...unselectedValues,
        });
      },
    },
  });
}
