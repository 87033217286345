import { format, isEqual, parseISO } from "date-fns";

/**
 * Get formatted period for start and end date in specific format
 * @param {String} startDate - start date of period
 * @param {String} endDate - end date of period
 * @param {String} [dateFormat] - default format is MM/dd/yy
 * @returns {String}
 */
export function formatDatePeriod(startDate, endDate, dateFormat = "MM/dd/yy") {
  const start = startDate ? format(parseISO(startDate), dateFormat) : "";
  const end = endDate ? format(parseISO(endDate), dateFormat) : "";
  return startDate && endDate
    ? isEqual(parseISO(startDate), parseISO(endDate))
      ? start
      : `${start} - ${end}`
    : "";
}

/**
 * It takes a string in the format of "HH:mm[:ss]" and returns a Date object with the time set to the given value
 * @param {String} value - The time string to parse.
 * @returns a Date object.
 */
export function parseTime(value) {
  if (typeof value !== "string") return null;
  const date = new Date();
  const [hours = 0, minutes = 0, seconds = 0] = value.split(":");
  date.setHours(hours, minutes, seconds, 0);
  return date;
}
