import Vue from "vue";

import { format } from "date-fns";
import { parseTime } from "@/utils/date";

export function useTaskDueTime() {
  return Vue.extend({
    name: "UseTaskDueTime",
    props: {
      task: {
        type: Object,
        default: () => ({
          dueTime: null,
        }),
      },
    },
    computed: {
      dueTimeFormatted() {
        const value = parseTime(this.task.dueTime);
        return value ? format(value, "h:mm a") : null;
      },
    },
  });
}
