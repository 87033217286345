<template>
  <v-menu offset-y :nudge-bottom="10">
    <template #activator="{ on, attrs }">
      <v-skeleton-loader
        class="d-inline-block mr-2"
        type="button"
        :loading="loading"
      >
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon left>$user</v-icon>
          {{ accessMenuText }}
        </v-btn>
      </v-skeleton-loader>
    </template>
    <v-list>
      <v-list-item-group v-model="modelValue">
        <v-list-item v-slot="{ active }" :value="false">
          <v-list-item-action>
            <VxCheckbox :input-value="active" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("daybook.setup.accessMenu.employeesAccess") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-slot="{ active }" :value="true">
          <v-list-item-action>
            <VxCheckbox :input-value="active" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("daybook.setup.accessMenu.managerAccess") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { VxCheckbox } from "@/core-ui";

export default {
  name: "TaskAccessMenu",
  components: { VxCheckbox },
  props: {
    task: {
      type: Object,
      default: () => ({ isManagerOnly: false }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accessMenuText() {
      return this.task.isManagerOnly
        ? this.$t("daybook.setup.accessMenu.managerAccess")
        : this.$t("daybook.setup.accessMenu.employeesAccess");
    },
    modelValue: {
      get() {
        return this.task.isManagerOnly;
      },
      set(value) {
        this.$emit(
          "update:task",
          Object.assign(this.task, { isManagerOnly: value })
        );
      },
    },
  },
};
</script>
