export * from "./TaskCreate.gql";
export * from "./TaskQuery.gql";
export * from "./TaskUpdate.gql";
export * from "./TaskReposition.gql";
export * from "./TaskDelete.gql";

export * from "./TaskListCreate.gql";
export * from "./TaskListQuery.gql";
export * from "./TaskListsQuery.gql";
export * from "./TaskListUpdate.gql";
export * from "./TaskListReposition.gql";
export * from "./TaskListDelete.gql";
