<template>
  <v-menu
    v-model="active"
    :close-on-content-click="false"
    :nudge-bottom="10"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-skeleton-loader
        class="d-inline-block mr-2"
        type="button"
        :loading="loading"
      >
        <v-btn
          :class="{ 'v-btn--active': dueTimeMenuInfo.active }"
          :color="dueTimeMenuInfo.active ? 'primary' : ''"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>$clock</v-icon>
          {{ dueTimeMenuInfo.text }}
        </v-btn>
      </v-skeleton-loader>
    </template>
    <v-time-picker
      ref="picker"
      v-model="modelValue"
      ampm-in-title
      @click:minute="onMinuteClick"
    >
      <v-spacer />
      <VxBtn text @click="onClearClick">{{
        $t("daybook.setup.dueTime.clearTime")
      }}</VxBtn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import { VxBtn } from "@/core-ui";
import { useTaskDueTime } from "../mixins/useTaskDueTime";

export default {
  name: "TaskDueTimeMenu",
  components: { VxBtn },
  mixins: [useTaskDueTime()],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    active: false,
  }),
  computed: {
    dueTimeMenuInfo() {
      return this.dueTimeFormatted
        ? { text: this.dueTimeFormatted, active: true }
        : { text: this.$t("daybook.setup.dueTime.text") };
    },
    modelValue: {
      get() {
        return this.task.dueTime;
      },
      set(value) {
        this.$emit("update:task", Object.assign(this.task, { dueTime: value }));
      },
    },
  },
  methods: {
    onClearClick() {
      this.active = false;
      this.modelValue = null;
      this.$refs.picker.selectingHour = true;
    },
    onMinuteClick() {
      this.active = false;
    },
  },
};
</script>
