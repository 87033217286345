<template>
  <v-menu :close-on-content-click="false" :nudge-bottom="10" offset-y>
    <template #activator="{ on, attrs }">
      <v-skeleton-loader
        class="d-inline-block mr-2"
        type="button"
        :loading="loading"
      >
        <v-btn
          :class="{ 'v-btn--active': repeatsMenuInfo.active }"
          :color="repeatsMenuInfo.active ? 'primary' : ''"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>$repeat</v-icon>
          {{ repeatsMenuInfo.text }}
        </v-btn>
      </v-skeleton-loader>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-action>
          <VxCheckbox
            :input-value="selectAllRepeats"
            @change="onSelectAllRepeatsChange"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("daybook.setup.taskRepeat.selectAllDaysText")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item-group
        v-model="selectedRepeats"
        active-class="primary--text"
        multiple
      >
        <v-list-item
          v-for="day in repeats"
          :key="day.shortName"
          v-slot="{ active }"
          :value="day.propName"
        >
          <v-list-item-action>
            <VxCheckbox :input-value="active" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> {{ day.name }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { VxCheckbox } from "@/core-ui";
import { useTaskRepeat } from "../mixins/useTaskRepeat";

export default {
  name: "TaskRepeatMenu",
  components: { VxCheckbox },
  mixins: [useTaskRepeat()],
  props: {
    task: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectAllRepeats: null,
    repeatsChanged: null,
  }),
  computed: {
    repeatsMenuInfo() {
      return this.repeatsFormatted
        ? { text: this.repeatsFormatted, active: true }
        : { text: this.$t("daybook.setup.taskRepeat.noSelectedRepeatText") };
    },
  },
  watch: {
    selectedRepeats: {
      deep: true,
      immediate: true,
      handler(values, oldValues = []) {
        values.length !== oldValues.length && this.updateTask(values);
        this.selectAllRepeats = values.length === this.repeats.length;
      },
    },
  },
  methods: {
    onSelectAllRepeatsChange(value) {
      this.selectedRepeats = value ? this.repeats.map((x) => x.propName) : [];
    },
  },
};
</script>
